<template>
    <div class="container padding-container online-education-order-list">
        <div class="border-container">
            <el-row class="search-container" type="flex" align="middle" justify="space-between">
                <el-col :lg="2" :sm="4" :xs="8">
                    <span class="list-title">订单管理</span>
                </el-col>
            </el-row>
            <div class="search-box" ref="search-box">
                <div class="search-div" ref="search-div">
                    <div class="search-left">
                        <div class="search-block">
                            <div class="search-key">交易单号：</div>
                            <div class="search-val">
                                <el-input v-model="searchForm.sn" placeholder="请输入"></el-input>
                            </div>
                        </div>
                        <div class="search-block">
                            <div class="search-key">商品：</div>
                            <div class="search-val">
                                <el-select v-model="searchForm.course_id" clearable placeholder="请选择">
                                    <el-option v-for="item of courseList" :key="item.id" :label="item.name" :value="item.id">
                                        {{item.media_type | courseTypeFilter }}: {{item.name}}
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="search-block">
                            <div class="search-key">用户：</div>
                            <div class="search-val">
                                <el-select v-model="searchForm.user_id" filterable clearable placeholder="请选择">
                                    <el-option v-for="item of userList" :key="item.id" :label="item.phone" :value="item.id">
                                        <span style="float: left">{{ item.phone }}</span>
                                        <span style="float: right; color: #8492a6; font-size: 13px;margin-left: 10px">{{ item.name }}</span>
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="search-block">
                            <div class="search-key">订单状态：</div>
                            <div class="search-val">
                                <el-select v-model="searchForm.status" clearable placeholder="请选择">
                                    <el-option v-for="item of ORDER.STATUS_LIST" :key="item.value" :label="item.name" :value="item.value"/>
                                </el-select>
                            </div>
                        </div>
                        <div class="search-block">
                            <div class="search-key">支付方式：</div>
                            <div class="search-val">
                                <el-select v-model="searchForm.pay_type" clearable placeholder="请选择">
                                    <el-option v-for="item of ORDER.TYPE_LIST" :key="item.value" :label="item.name" :value="item.value"/>
                                </el-select>
                            </div>
                        </div>
                        <div class="search-block">
                            <div class="search-key">渠道来源：</div>
                            <div class="search-val">
                                <el-select v-model="searchForm.source_type" clearable placeholder="请选择">
                                    <el-option v-for="item of ORDER.SOURCE_TYPE_LIST" :key="item.value" :label="item.name" :value="item.value"/>
                                </el-select>
                            </div>
                        </div>
                        <div class="search-block">
                            <div class="search-key">下单时间：</div>
                            <div class="search-val">
                                <el-date-picker v-model="searchForm.time" clearable type="daterange" value-format='timestamp'
                                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                    <div class="search-right">
                        <el-button class="cancel" @click="handleReset">重置</el-button>
                        <el-button type="primary" @click="pageChange(1)">查询</el-button>
                        <div class="shrink-btn" @click="openSearch = !openSearch">
                            {{ openSearch ? '收起' : '展开' }}<img class="arrow-top-red" :class="{'deg180': !openSearch}" src="@/assets/images/arrow-top-red.png" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-container">
                <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                    :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                    :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">

                    <!-- <el-table-column prop="id" label="ID" min-width="60" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.id}}
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="sn" label="订单号" min-width="180" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="username" label="用户名" min-width="120" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{ scope.row.user ? scope.row.user.name : '-'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="商品名" min-width="200" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.course ? scope.row.course.name : '-'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="price" label="价格" min-width="100">
                        <template slot-scope="scope">
                            <div class="text-colourful">¥ {{scope.row.price / 100}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="money" label="实际支付金额" min-width="120">
                        <template slot-scope="scope">
                            <div class="text-colourful">¥ {{scope.row.charge / 100}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="订单状态" min-width="120">
                        <template slot-scope="scope">
                            <div class="order-status-div">
                                <i class="iconfont icon-point" :class="scope.row.status | payStatusColorFilter"></i>
                                {{scope.row.status | orderStatusFilter}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pay_type" label="支付方式" min-width="120">
                        <template slot-scope="scope">
                            <i class="iconfont" :class="scope.row.pay_type | orderTypeIconFilter"></i>
                            {{scope.row.pay_type | orderTypeFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="channel" label="来源渠道" min-width="120">
                        <template slot-scope="scope">
                            {{scope.row.source_type | orderSourceFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="下单时间" min-width="155" align="center">
                        <template slot-scope="scope">
                            {{scope.row.create_time | timeFilter}}
                        </template>
                    </el-table-column>
                    <div class="" slot="empty">
                        <no-data></no-data>
                    </div>
                </el-table>
            </div>
            <div class="page-container">
                <el-pagination layout="total, sizes, prev, pager, next" background @size-change="pageSizeChange"
                    @current-change="pageChange" :current-page="currentPage" :total="total" :page-size="pageSize">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import Core from '@/core';
const USER_TYPE = Core.Const.USER_TYPE;
const ORDER = Core.Const.ORDER;

let searchBoxDom = null,searchDivDom = null
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {},
    data() {
        return {
            userType: Core.Data.getUserType(),
            USER_TYPE,
            ORDER,
            total: 0,
            currentPage: 1,
            pageSize: 10,
            openSearch: false,
            userList: [],
            courseList: [],
            searchForm: {
                sn: '',
                course_id: '',
                user_id: '',
                status: '',
                pay_type: '',
                source_type: '',
                time: [],
            },

            tableData: [],
        };
    },
    watch: {
        openSearch() {
            this.openSearchChange();
        }
    },
    computed: {},
    created() {},
    mounted() {
        this.initDom();
        this.getTableData();
        this.getAllUserList();
        this.getAllCourseList();
    },
    methods: {
        getAllUserList() {
            Core.Api.User.allUserList().then(res => {
                console.log('getUserListAll', res);
                this.userList = res.list;
            });
        },
        async getAllCourseList() {
            let page = 1
            let total = 0
            await Core.Api.Course.list(
                page,
                20
            ).then(res => {
                total = res.count
                this.courseList = res.list;
            });
            while(this.courseList.length < total) {
                page++
                await Core.Api.Course.list(
                    page,
                    20
                ).then(res => {
                    this.courseList = [...this.courseList, ...res.list];
                });
            }
            console.log('this.courseList:', this.courseList)
        },
        initDom() {
            searchBoxDom = this.$refs['search-box'];
            searchDivDom = this.$refs['search-div'];
            searchBoxDom.style.height = '82px';
        },
        openSearchChange() {
            if (this.openSearch) {
                searchBoxDom.style.height = `${searchDivDom.offsetHeight}px`;
            } else {
                searchBoxDom.style.height = '82px';
            }
        },
        pageChange(page) {
            this.currentPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },

        getTableData() {
            let search = this.searchForm;
            Core.Api.CourseOrder.list(
                this.currentPage,
                this.pageSize,
                search.sn,
                search.course_id,
                search.user_id,
                search.status,
                search.pay_type,
                search.source_type,
                search.time[0] / 1000 || '',
                search.time[1] / 1000 || '',
            ).then((res) => {
                console.log('CourseOrder list -> res', res);
                this.total = res.count;
                this.tableData = res.list;
            });
        },
        handleReset() {
            Object.assign(this.$data.searchForm, this.$options.data().searchForm)
            this.pageChange(1);
        },
    }
};
</script>

<style lang="scss">
.online-education-order-list {
    @import '@/common/styles/table.scss';
    .search-box {
        transition: 300ms;
        overflow: hidden;
        .search-div {
            display: flex;
            background: white;
            padding: 25px 14px 0px;
            .search-left {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                width: calc(100% - 130px);
                .search-block {
                    display: flex;
                    align-items: center;
                    margin-bottom: 25px;
                    margin-right: 30px;
                    .search-key {
                        width: 70px;
                    }
                    .search-val {
                        > .el-input,
                        > .el-select {
                            width: 200px;
                        }
                    }
                }
            }
            .search-right {
                min-width: 180px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 32px;
                .shrink-btn {
                    min-width: 50px;
                    text-align: center;
                    height: 32px;
                    line-height: 32px;
                    margin-left: 10px;
                    color: #dc6f7c;
                    cursor: pointer;
                    user-select: none;
                    font-size: 10px;
                    .arrow-top-red {
                        width: 10px;
                        height: 6px;
                        transition: 100ms;
                        margin-left: 3px;
                    }
                    .deg180 {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    .table-container {
        padding: 0 14px;
        background: white;
        .order-status-div {
            display: flex;
            align-items: center;
            .order-status-background {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: pink;
                margin-right: 3px;
            }
        }
    }
}
</style>
